<template>
  <v-container fluid class="px-15">
    <h1>{{ trl("DIVISIONS_READ_menu") }}</h1>

    <v-banner rounded color="primary" v-if="divisions.length === 0 && !$route.params.id">Es wurden noch keine Units angelegt.</v-banner>
    
    <v-row class="mt-6" >
     
      <v-list v-if="divisions.filter(d => !d.deleted_at).length > 0" min-height="75vh">
        <v-list-item-group active-class="current" color="primary">
          <v-list-item
            v-for="item in divisions.filter(d => !d.deleted_at)"
            :key="item.id"
            :to="{
              name: 'Details',
              params: { id: item.id },
            }"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-select-group</v-icon>
                {{ item.name }} <small>{{ yearsActive(item) }}</small>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-col>
        <router-view></router-view>
        <v-layout align-self="center" v-if="!$route.params.id && divisions.length > 0">Bitte wähle eine Unit.</v-layout>
      </v-col>
    </v-row>
    
    <v-btn
      v-if="current.permissions.DIVISIONS_WRITE"
      color="primary"
      elevation="4"
      fab
      large
      :to="{
        name: 'DivisionEdit',
        params: { id: 'new' },
      }"
      class="fab-new"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { trl } from "@/utils/strings";

export default {
  methods: {
    trl,
    yearsActive(d) {
      let yr = new Date().getFullYear();
      if (d.fy_first && d.fy_first > yr) return ` - ab ${d.fy_first}`;
      if (d.fy_last && d.fy_last <= yr) return ` - bis Ende ${d.fy_last}`;

      if (
            d.fy_first && d.fy_first > yr && 
            d.fy_last && d.fy_last <= yr
      ) return ` - ${d.fy_first} - ${d.fy_last}`

      return "";

      if (!d.fy_first && !d.fy_last) return "";
      if (!d.fy_first && d.fy_last) return ` - bis ${d.fy_last}`;
      return ` - ${d.fy_first} - ${d.fy_last}`;
    }
    
  },
  created() {
    this.$store.dispatch("req", {method: "GET", route: "divisions", mutation: "divisions"});
    this.$router.push({ name: 'Details', params:{id: this.$route.params.id || this.divisions[0].id }})
    // Unit-Kunden aktualisieren
    this.$store.dispatch("req", { route: "clients", mutation: "clients"});
  },
  computed: {
    ...mapGetters(["current", "divisions"])
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  width: 280px;
  margin-right: 4rem;

  .v-list-item {
    min-height: auto;
  }
}
</style>