var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-15",attrs:{"fluid":""}},[_c('h1',[_vm._v(_vm._s(_vm.trl("DIVISIONS_READ_menu")))]),(_vm.divisions.length === 0 && !_vm.$route.params.id)?_c('v-banner',{attrs:{"rounded":"","color":"primary"}},[_vm._v("Es wurden noch keine Units angelegt.")]):_vm._e(),_c('v-row',{staticClass:"mt-6"},[(_vm.divisions.filter(d => !d.deleted_at).length > 0)?_c('v-list',{attrs:{"min-height":"75vh"}},[_c('v-list-item-group',{attrs:{"active-class":"current","color":"primary"}},_vm._l((_vm.divisions.filter(d => !d.deleted_at)),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
            name: 'Details',
            params: { id: item.id },
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-select-group")]),_vm._v(" "+_vm._s(item.name)+" "),_c('small',[_vm._v(_vm._s(_vm.yearsActive(item)))])],1)],1)],1)}),1)],1):_vm._e(),_c('v-col',[_c('router-view'),(!_vm.$route.params.id && _vm.divisions.length > 0)?_c('v-layout',{attrs:{"align-self":"center"}},[_vm._v("Bitte wähle eine Unit.")]):_vm._e()],1)],1),(_vm.current.permissions.DIVISIONS_WRITE)?_c('v-btn',{staticClass:"fab-new",attrs:{"color":"primary","elevation":"4","fab":"","large":"","to":{
      name: 'DivisionEdit',
      params: { id: 'new' },
    }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }